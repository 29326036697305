// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// require("bootstrap");

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/css/all"

// import 'alpine-turbo-drive-adapter'
// require("alpinejs")

// import "bootstrap"
import "../stylesheets/application.scss"
// import '../js/bootstrap_js_files.js'  

// import "trix"
import "signature_pad"
import "@rails/actiontext"

// import "alpine-turbo-drive-adapter"
// import "alpinejs"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// $(document).on("turbolinks:load", function() {
//   gtag('config', 'G-EFFMFMQD91', {'page_location': event.data.url});
// })

console.log("Hello World from Webpacker");
